interface DomoreportBenefitsProps {
  type: string
}

export default function DomoreportBenefits(props: DomoreportBenefitsProps) {
  return (
    <div className="flex flex-col items-center justify-center py-12 px-8">
      <h2 className="font-black text-5xl text-center mb-4">
        {props.type === 'inspector' ? 'What your client gets' : 'What you get'}
      </h2>
      <p className="mb-12 text-center max-w-md font-heading">
        The Domoreport comes complete with all the tools{' '}
        {props.type === 'inspector'
          ? 'your client needs to be confident in their purchase'
          : 'you need to be confident in your purchase'}
        .
      </p>

      {props.type === 'inspector' && (
        <div className="flex items-center justify-center w-full lg:mt-0 mt-12 mb-28">
          <iframe
            width="844"
            height="475"
            src="https://www.youtube.com/embed/ILXF33MJdpc?hl=en_US&amp;rel=0&amp;autohide=1&amp;autoplay=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            className="rounded shadow-lg lg:inline hidden"
            allowFullScreen
          />

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ILXF33MJdpc?hl=en_US&amp;rel=0&amp;autohide=1&amp;autoplay=0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            className="rounded shadow-lg lg:hidden inline"
            allowFullScreen
          />
        </div>
      )}

      <div className="relative flex xl:flex-row flex-col justify-center xl:w-full md:w-1/2 sm:w-2/3 w-full xl:pt-12 xl:pb-24">
        <div className="xl:w-1/2 w-full max-w-lg">
          <img src="/images/offers/estimates.jpg" className="w-full" />
        </div>

        <div className="flex flex-col justify-center xl:w-1/2 w-full xl:px-36 lg:px-12 px-8 xl:py-0 py-12">
          <h4 className="font-black font-heading text-3xl max-w-sm mb-4">
            ZIP Code specific repair estimates broken down by materials and
            labor
          </h4>
          <p className="max-w-md leading-7">
            Majordomo's cost estimates are based on more than 30 years of
            research on 20,000 project estimates from 35,000 providers of
            materials and labor across the US. Every Domoreport is reviewed by
            an industry expert before it's sent to{' '}
            {props.type === 'inspector' ? 'your client' : 'you'}.
          </p>
        </div>

        <div className="absolute xl:top-0 left-1/3 xl:inline hidden">
          <img
            src="/images/offers/repair-details-mockup.png"
            className="xl:w-56 lg:w-64"
          />
        </div>
      </div>

      <div className="relative flex xl:flex-row flex-col justify-center xl:w-full md:w-1/2 sm:w-2/3 w-full xl:py-24">
        <div className="flex flex-col justify-center xl:w-1/2 w-full xl:px-36 lg:px-12 px-8 xl:py-0 py-12 xl:order-1 order-2">
          <h4 className="font-black font-heading text-3xl max-w-sm mb-4">
            Prioritized repairs by severity and urgency
          </h4>
          <p className="max-w-md leading-7">
            At the core of the Domoreport is a list of repairs based on defects
            identified in the inspection. We tell{' '}
            {props.type === 'inspector' ? 'your client' : 'you'} where the
            repairs need to happen, prioritize them, estimate costs, and
            identify the type of contractors who could do the work – all linked
            to the page number of the home inspection report.
          </p>
        </div>

        <div className="xl:w-1/2 w-full max-w-lg xl:order-2 order-1">
          <img src="/images/offers/Group-129.png" className="w-full" />
        </div>

        <div className="absolute xl:top-12 top-24 right-1/3 xl:inline hidden">
          <img
            src="/images/offers/domoreport-mockup.png"
            className="xl:w-56 lg:w-64"
          />
        </div>
      </div>

      <div className="relative flex xl:flex-row flex-col justify-center xl:w-full md:w-1/2 sm:w-2/3 w-full xl:py-24">
        <div className="xl:w-1/2 w-full max-w-lg">
          <img src="/images/offers/image-9.png" className="w-full" />
        </div>

        <div className="flex flex-col justify-center xl:w-1/2 w-full xl:px-36 lg:px-12 px-8 xl:py-0 py-12">
          <h4 className="font-black font-heading text-3xl max-w-sm mb-4">
            Integrated request list creator
          </h4>
          <p className="max-w-md leading-7">
            {props.type === 'inspector' ? 'Your client can build' : 'Build'} a
            custom request list of repair requests, credit amounts, and items
            accepted as-is. Export a professional-looking addendum attachment to
            use when negotiating with sellers.
          </p>
        </div>

        <div className="absolute xl:top-12 top-24 left-1/3 xl:inline hidden">
          <img
            src="/images/offers/request-list-mockup.png"
            className="xl:w-56 lg:w-64"
          />
        </div>
      </div>
    </div>
  )
}
