import { BadgeCheckIcon } from '@heroicons/react/solid'
import { Button } from '@components/UI/Button'

interface FeaturesListProps {
  title?: string
  url?: string
  buttonText?: string
}

export default function FeaturesList(props: FeaturesListProps) {
  return (
    <>
      <div className="flex flex-wrap lg:max-w-5xl max-w-xl mx-auto lg:pb-36 pb-12 px-8">
        {props.title && (
          <h4 className="font-black text-3xl text-center w-full mb-20">
            {props.title}
          </h4>
        )}

        <div className="lg:w-1/2 w-full">
          <div className="w-full grid grid-cols-3 gap-x-4 gap-y-1">
            <div className="col-span-3">
              <p className="font-black text-lg">
                Beautiful interactive reports
              </p>
            </div>

            <div className="col-span-2">
              <p className="text-sm">Repair & maintenance recommendations</p>
            </div>

            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>

            <div className="col-span-2">
              <p className="text-sm">
                Full and detailed analysis of all repairs
              </p>
            </div>

            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>

            <div className="col-span-2">
              <p className="text-sm">24-hour turnaround guarantee</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>
            <div className="col-span-2">
              <p className="text-sm">Integrated request list builder</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>
            <div className="col-span-2">
              <p className="text-sm">Simple CSV repair list download</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>
            <div className="col-span-2">
              <p className="text-sm">Archive old Domoreports</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>
            <div className="col-span-2">
              <p className="text-sm">Inspection report securely saved online</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>
            <div className="col-span-2">
              <p className="text-sm">
                Secure unlimited document storage.{' '}
                <a
                  href="/features/documents"
                  rel="noopener noreferrer"
                  className="text-accentBlue hover:underline"
                >
                  Learn More
                </a>
              </p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>
            <div className="col-span-2">
              <p className="text-sm">
                Explore: US, state, county, and city resources.{' '}
                <a
                  href="/features/explore"
                  rel="noopener noreferrer"
                  className="text-accentBlue hover:underline"
                >
                  Learn More
                </a>
              </p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>
          </div>

          <div className="w-full mt-4 grid grid-cols-3 gap-x-4 gap-y-1">
            <div className="col-span-3">
              <p className="font-black text-lg">Security</p>
            </div>

            <div className="col-span-2">
              <p className="text-sm">
                Easy and secure built-in sharing.{' '}
                <a
                  href="https://intercom.help/majordomo/en/articles/3216497-how-do-i-share-my-domoreport"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-accentBlue hover:underline"
                >
                  Learn More
                </a>
              </p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>
            <div className="col-span-2">
              <p className="text-sm">Always available: 24 hour access</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>
            <div className="col-span-2">
              <p className="text-sm">Lifetime account access</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>
            <div className="col-span-2">
              <p className="text-sm">SSL encryption</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="text-black md:mx-auto ml-auto"
              />
            </div>
          </div>
        </div>

        <div className="lg:w-1/2 w-full">
          <div className="w-full grid grid-cols-3 gap-x-4 gap-y-1">
            <div className="col-span-3">
              <p className="text-lg font-black">Easy data input</p>
            </div>

            <div className="col-span-2">
              <p className="text-sm">Simple Inspection PDF Upload</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="ml-auto text-black md:mx-auto"
              />
            </div>

            <div className="col-span-2">
              <p className="text-sm">Property photo uploads</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="ml-auto text-black md:mx-auto"
              />
            </div>
          </div>

          <div className="w-full mt-4 grid grid-cols-3 gap-x-4 gap-y-1">
            <div className="col-span-3">
              <p className="text-lg font-black">Support</p>
            </div>

            <div className="col-span-2">
              <p className="text-sm">Expert email support</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="ml-auto text-black md:mx-auto"
              />
            </div>

            <div className="col-span-2">
              <p className="text-sm">Live chat support</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="ml-auto text-black md:mx-auto"
              />
            </div>

            <div className="col-span-2">
              <p className="text-sm">
                Comprehensive knowledge base.{' '}
                <a
                  href="https://intercom.help/majordomo"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-accentBlue hover:underline"
                >
                  Learn More
                </a>
              </p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="ml-auto text-black md:mx-auto"
              />
            </div>

            <div className="col-span-2">
              <p className="text-sm">Zoom support during business hours</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="ml-auto text-black md:mx-auto"
              />
            </div>

            <div className="col-span-2">
              <p className="text-sm">Phone support during business hours</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="ml-auto text-black md:mx-auto"
              />
            </div>

            <div className="col-span-2">
              <p className="text-sm">Training webinars</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="ml-auto text-black md:mx-auto"
              />
            </div>

            <div className="col-span-2">
              <p className="text-sm">Lifetime bug fixes and improvement</p>
            </div>
            <div className="col-span-1">
              <BadgeCheckIcon
                width={24}
                height={24}
                className="ml-auto text-black md:mx-auto"
              />
            </div>
          </div>
        </div>

        {props.url && props.buttonText && (
          <div className="flex items-center justify-center w-full mt-20">
            <a href={props.url} target="_blank" rel="noopener noreferrer">
              <Button variant="primary" clipSide="right">
                {props.buttonText}
              </Button>
            </a>
          </div>
        )}
      </div>
    </>
  )
}
